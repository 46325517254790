
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    targetSelector: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
      default: "",
    },
  },
  mounted() {
    this.setTargetElement();
    window.addEventListener("resize", this.setTargetElement);

    this.$nextTick(() => {
      setTimeout(() => {
        this.isOverlayAnimated = true;
      }, 500);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setTargetElement);
  },
  data() {
    return {
      isVisible: true,
      isOverlayAnimated: false,
      targetElement: null,
      highlightX: 0,
      highlightY: 0,
      highlightWidth: 0,
      highlightHeight: 0,
      tooltipStyle: {},
      clickableHighlightStyle: {},
    };
  },
  methods: {
    setTargetElement() {
      this.targetElement = document.querySelector(this.targetSelector);
      if (this.targetElement) {
        const rect = this.targetElement.getBoundingClientRect();
        this.highlightX = rect.left - 4;
        this.highlightY = rect.top - 4;
        this.highlightWidth = rect.width + 8;
        this.highlightHeight = rect.height + 8;

        // 透明クリックエリアのスタイル設定
        this.clickableHighlightStyle = {
          position: "absolute",
          top: `${this.highlightY}px`,
          left: `${this.highlightX}px`,
          width: `${this.highlightWidth}px`,
          height: `${this.highlightHeight}px`,
          pointerEvents: "auto",
          cursor: "pointer",
        };

        // 吹き出し位置の設定
        this.tooltipStyle = {
          top: `${rect.bottom + 18}px`,
          right: `${window.innerWidth - rect.right - 12}px`,
          "--triangle-position": `${rect.width / 2 - 4}px`,
        };
      }
    },
    closeOnboarding() {
      this.isVisible = false;
      this.$emit("onboarding-finished");
      this.$emit("onboarding-close-clicked");
    },
    highlightClick(event) {
      this.targetElement.click();
      this.isVisible = false;
      this.$emit("onboarding-finished");
      this.$emit("onboarding-target-clicked");
    },
  },
});

interface Props {
  targetSelector: string;
  message: string;
}

interface Data {
  isVisible: boolean;
  isOverlayAnimated: boolean;
  targetElement: any;
  highlightX: number;
  highlightY: number;
  highlightWidth: number;
  highlightHeight: number;
  tooltipStyle: object;
  clickableHighlightStyle: object;
}

interface Computed {}

interface Methods {
  setTargetElement(): void;
  closeOnboarding(): void;
  highlightClick(event: any): void;
}
