
import { Novel } from "@/lib/models";
import Vue from "vue";
import AgentNovelListItem from "@/components/molecules/AgentNovelListItem.vue";
import NonAgentNovelListItem from "@/components/molecules/NonAgentNovelListItem.vue";
import Onboarding from "@/components/molecules/OnBoarding.vue";
import { NolaAnalytics, NolaItemId } from "@/lib/utils/analytics";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    AgentNovelListItem,
    NonAgentNovelListItem,
    Onboarding,
  },
  computed: {
    novels() {
      return this.$store.getters["novelModule/novels"].filter((i) => i.title !== "はじめてのNola");
    },
    associatedNovels() {
      const { novels } = this;
      return novels.filter((novel) => novel.agentStatus === 1);
    },
    nonAssociatedNovels() {
      const { novels } = this;
      return novels.filter((novel) => novel.agentStatus !== 1);
    },
  },
  created() {
    const agentOnBoarding = sessionStorage.getItem("agentOnBoarding");
    this.showOnboarding = agentOnBoarding && agentOnBoarding === "true";
  },
  data() {
    return {
      nolaAnalytics: new NolaAnalytics(this.$gtm, this.$store),
      isModeAgentStartedNovels: false,
      showOnboarding: false,
    };
  },
  methods: {
    onClickAssociatedNovels() {
      this.isModeAgentStartedNovels = true;
    },
    onClickNonAssociatedNovels() {
      this.isModeAgentStartedNovels = false;
    },
    onClickCreateNovel() {
      this.$router.push({ name: "novelCreate" });
    },
    onClickAssociate() {
      this.isModeAgentStartedNovels = false;
    },
    handleOnboardingFinished() {
      this.showOnboarding = false;
      sessionStorage.removeItem("agentOnBoarding");
    },
    onboardingCloseClicked() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.OnBoardingClose, this.nonAssociatedNovels[0].novelId);
    },
    onboardingTargetClicked() {
      this.nolaAnalytics.logButtonEvent(NolaItemId.OnBoardingAdd, this.nonAssociatedNovels[0].novelId);
    },
  },
});

interface Data {
  nolaAnalytics: NolaAnalytics;
  isModeAgentStartedNovels: boolean;
  showOnboarding: boolean;
}

interface Methods {
  onClickAssociatedNovels(): void;
  onClickNonAssociatedNovels(): void;
  onClickCreateNovel(): void;
  onClickAssociate(): void;
  handleOnboardingFinished(): void;
  onboardingCloseClicked(): void;
  onboardingTargetClicked(): void;
}

interface Computed {
  novels: Novel[];
  associatedNovels: Novel[];
  nonAssociatedNovels: Novel[];
}

interface Props {}
